<template>
    <div class="flex-col baseInfoContainer">
        <span class="text-14-black33-b">基础信息</span>
        <div class="flex-array-h">
            <div v-for="(item,index) in baseInfo" :key="index" class="width-parent-48 margin-top-8">
                <base-form-view :out-data="item" @onValueChange="onFormValueChange(item,$event)"/>
            </div>
        </div>
        <slot name="dynamicForm"></slot>
        <span class="text-14-black33-b margin-top-16">领证信息</span>
        <div class="flex-array-h match-parent-w">
            <div v-for="(item,index) in getCertificateInfo" :key="index" :class="getItemWidth(item)">
                <div v-if="item.type=== 'conditionView'">
                    <condition-form-view class="match-parent-w" :condition-data="item"
                                         @onConditionDataChange="onConditionChange(item,$event)"/>
                </div>
                <base-form-view v-else :out-data="item" @onValueChange="onFormValueChange(item,$event)"/>
            </div>
        </div>
    </div>

</template>

<script>

    import BaseFormView from "../../formView";
    import ConditionFormView from "../../conditionView";

    export default {
        name: 'baseInfoView',
        components: {ConditionFormView, BaseFormView},
        data() {
            return {
                notaryInfo: null,
                cacheDetailData: null,
                baseInfo: [// 基本信息
                    {
                        key: "application", type: "select", label: "用途", value: "", required: true
                    },
                    {
                        key: "amount", type: "count", label: "证书份数", required: true, value: 1
                    },
                    {
                        key: "useLocation",
                        type: "select",
                        label: "使用地",
                        required: true,
                        // editLimit: !this.isSupportGatw,// 不支持涉外，就设置默认值即可，不允许其编辑
                        // opinions: this.usePlaceOptions
                    },
                    {
                        key: "translation",
                        type: "select",
                        label: "译文",
                        // editLimit: !this.isSupportGatw,// 不支持涉外，就设置默认值即可，不允许其编辑
                        required: true,
                        // opinions: this.transOptions
                    },
                    {
                        key: "notaryAgencyName",
                        type: "label",
                        // type: "select",
                        label: "公证处",
                        time: 0,
                        required: true,
                        // opinions: this.agencyList
                    },
                    {
                        key: "contractId",
                        type: "hidden",
                        // type: "select",
                        label: "金融产品",
                        // editLimit: !this.isSupportGatw,// 不支持涉外，就设置默认值即可，不允许其编辑
                        required: true,
                        // opinions: this.transOptions
                    },
                ],
                getCertificateInfo: [// 领证信息
                    {
                        key: "electronicCertificate",
                        type: "conditionView",
                        label: "是否需要电子公证书",
                        time: 0,
                        // value: 1,
                        // editLimit: !this.isSupportEcert,// 不支持电子公证书，就设置默认值否，不允许其编辑
                        required: true,
                        value: 0,
                        opinions: [
                            {value: 1, label: "是"},
                            {value: 0, label: "否"},
                        ],
                        items: [
                            {
                                key: "postEmail",
                                type: "single",
                                label: "电子邮箱",
                                conditionValue: '1',
                                value: null,
                                required: true,
                                depend: "electronicCertificate",
                                hintText: "如您需要电子公证书，请提供有效的本人电子邮箱地址",
                                regx: "/^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z0-9]+$/"
                            },
                        ],
                    },
                    {
                        key: "paperCertificate",
                        type: "conditionView",
                        label: "是否需要纸质公证书",
                        value: 0,
                        time: 0,
                        opinions: [
                            {value: 0, label: "否"},
                            {value: 1, label: "是"},
                        ],
                        required: true,
                        items: [
                            {
                                key: "pickupMethod",
                                depend: "paperCertificate",
                                type: "conditionView",
                                conditionValue: '1',
                                label: "领证方式",
                                required: true,
                                time: 0,
                                opinions: [
                                    {
                                        "label": "邮寄",
                                        "value": "POST"
                                    },
                                    {
                                        "label": "自取",
                                        "value": "SELF"
                                    },
                                ],
                                items: [
                                    {
                                        key: "postAddress",
                                        type: "single",
                                        conditionValue: 'POST',
                                        label: "邮寄地址",
                                        value: "",
                                        required: true,
                                        hintText: "如您需要邮寄纸质公证书，请提供本人有效邮寄地址，费用自理",
                                        depend: "pickupMethod"
                                    },
                                    {
                                        key: "postTelphone",
                                        type: "single",
                                        label: "手机号码",
                                        conditionValue: 'POST',
                                        value: "",
                                        maxLength: 11,
                                        regx: "/^1[3456789]\\d{9}$/",
                                        required: true,
                                        hintText: "公证员将与您联系确认材料、收费、签字请保持手机畅通",
                                    },
                                    {
                                        key: "receiveCertificateAddress",
                                        type: "label",
                                        conditionValue: 'SELF',
                                        label: "领证地址",
                                        time: 0,
                                        // value: this.curInsitution.receiveCertificateAddress,
                                        value: '',
                                        required: false,
                                        hintText: "如您需要自行领取纸质公证书，请到以上地址领证",
                                        depend: "pickupMethod"
                                    },
                                ]
                            },
                        ]
                    },
                    {key: "remark", type: "single", label: "备注", value: ""},
                ],
            }
        },
        props: {
            preData: {
                type: Object,
                default() {
                    return {};
                }
            },
        },
        watch: {
            preData: {
                handler(newValue) {
                    this.analysisData(newValue);
                },
                immediate: true
            },
        },
        computed: {},
        mounted() {
        },
        methods: {
            analysisData(preData) {
                let {purpose, territory, trans, notaryInfo} = preData;
                let copy = JSON.parse(JSON.stringify(this.baseInfo));
                let copyG = JSON.parse(JSON.stringify(this.getCertificateInfo));
                this.notaryInfo = notaryInfo;
                // 初始化基础信息
                copy[0].opinions = purpose;
                copy[2].opinions = territory;
                copy[3].opinions = trans;

                // notaryInfo = {
                //     name: '大足公证处', receiveCertificateAddress: '大足公证处xx号',
                //     provideECert: 1, paperCertDeliveryWayList: [
                //         "SELF",
                //         // "POST"
                //     ]
                // };

                // 初始化领证信息
                if (notaryInfo) {
                    let {name, provideECert, paperCertDeliveryWayList, receiveCertificateAddress} = notaryInfo;
                    // 设置公证处名字
                    copy[4].value = name;
                    // 设置领证地址
                    copyG[1].items[0].items[2].value = receiveCertificateAddress;
                    // 设置领证方式-只有单个的时候-才进行修改
                    if (!this.$verify.isEmptyArray(paperCertDeliveryWayList) && paperCertDeliveryWayList.length === 1) {
                        let old = [{"label": "邮寄", "value": "POST"}, {"label": "自取", "value": "SELF"}];
                        copyG[1].items[0].opinions = old.filter(v => {
                            return v.value == paperCertDeliveryWayList[0];
                        })
                    }
                    // 设置是否需要电子证书
                    if (provideECert === 0) {
                        // 删除掉电子证书
                        copyG.splice(0, 1);
                    }
                }
                this.$set(this, 'baseInfo', copy);
                this.$set(this, 'getCertificateInfo', copyG);
            },
            refreshData(newData) {
                this.cacheDetailData = newData;
                this.$set(this, 'baseInfo', this.setDgValue(this.baseInfo, newData));
                this.$set(this, 'getCertificateInfo', this.setDgValue(this.getCertificateInfo, newData));
            },
            setDgValue(array, newData) {
                let copy = JSON.parse(JSON.stringify(array))
                copy.forEach(v => {
                    let {key, items, type} = v;
                    if (newData.hasOwnProperty(key)) {
                        let newValue = newData[key];
                        v.value = newValue;
                        if (type === 'conditionView') {
                            let childrenFresh = items.filter(item => {
                                return item.conditionValue == newValue;
                            });
                            if (!this.$verify.isEmptyArray(childrenFresh)) {
                                let realChildren = this.setDgValue(childrenFresh, newData);
                                v.children = realChildren;
                                this.$set(v, 'children', this.setDgValue(childrenFresh, newData));
                            }
                        }
                    }
                });
                return copy;
            },
            verifyBaseData() {
                console.log('this.baseInfo=', this.baseInfo)
                for (const item of this.baseInfo) {
                    if (!this.verifyData(item)) {
                        return false;
                    }
                }
                console.log('基础信息数据校验通过！');
                return true;
            },
            verifyGetData() {
                console.log('this.getCertificateInfo=', this.getCertificateInfo)
                for (const item of this.getCertificateInfo) {
                    if (!this.verifyData(item)) {
                        return false;
                    }
                }
                console.log('领证信息数据校验通过！');
                return true;
            },
            packDetailData() {
                if (this.$verify.isEmpty(this.cacheDetailData)) {
                    this.cacheDetailData = {};
                }
                this.setValueToCache(this.baseInfo, this.cacheDetailData);
                this.setValueToCache(this.getCertificateInfo, this.cacheDetailData);
                console.log('this.cacheDetailData=', this.cacheDetailData)
                return this.cacheDetailData;
            },
            setValueToCache(arrayData, cache) {
                for (const {key, value, children} of arrayData) {
                    cache[key] = value;
                    if (!this.$verify.isEmptyArray(children)) {
                        this.setValueToCache(children, cache);
                    }
                }
            },
            getSaveData() {
                return {baseInfoData: this.baseInfo, getInfoData: this.getCertificateInfo}
            },
            verifyData(data, fatherName = '') {
                let {label, value, children, required, regx, type} = data;
                let hintName = this.$verify.isEmpty(fatherName) ? `[${label}]` : `${fatherName}-[${label}]`;
                console.log(hintName)
                if (type === 'hidden') return true;
                if (required && this.$verify.isEmpty(value)) {
                    this.toast(`${hintName}不能为空！`);
                    return false;
                }

                if (!this.$verify.isEmpty(value) && !this.$verify.isEmpty(regx)) {
                    let exp = this.$tool.util.getRealReg(regx);
                    if (!(exp.test(value))) {
                        this.toast(`${hintName}的格式不正确！`);
                        return false;
                    }
                }
                if (type === 'conditionView') {
                    // value为true或者1 但children为空
                    if (!value) return true;
                    if (this.$verify.isEmptyArray(children)) {
                        this.toast(`${hintName}的子数据有误！`);
                        return false;
                    }
                    for (const child of children) {
                        if (!this.verifyData(child, hintName)) {
                            return false;
                        }
                    }
                }
                return true;
            },
            toast(msg, flag = false) {
                let param = {event: 'toast', data: {msg, flag}};
                this.$root.$emit('jqBridge', param);
            },
            getItemWidth(formItem) {
                let style = '';
                switch (formItem.type) {
                    case 'conditionView':
                        style += ' match-parent-w';
                        break
                    default:
                        style += ' margin-top-8 width-parent-48';
                        break
                }
                return style;
            },
            onConditionChange(item, newItem) {
                // 将newItem中改变的值赋值给item
                for (const newItemKey in newItem) {
                    if (newItem.hasOwnProperty(newItemKey)) {
                        let value = newItem[newItemKey];
                        if (item.hasOwnProperty(newItemKey) && item[newItemKey] === value) {
                            continue;
                        }
                        this.$set(item, newItemKey, value);
                    }
                }
            },
            onFormValueChange(item, newValue) {
                this.$set(item, 'value', newValue);
            }
        }

    }
</script>

<style scoped lang="scss">
    @import "src/css/form-style";
</style>
