<template>
    <div class="flex-array-h">
        <base-form-view class="width-parent-48 conditionBox margin-top-16" :out-data="condition"
                        @onValueChange="onConditionSelect"/>
        <div v-for="(item,index) in children" :key="index" :class="getItemWidth(item)">
            <div :is="conditionView" :condition-data="item"
                 v-if="item.type === 'condition-picker' || item.type === 'condition-select' ||item.type === 'conditionView'"
                 @onConditionDataChange="onConditionChange(item,$event)"/>
            <div :is="groupView" v-else-if="item.type=== 'group'" :group-data="item"
                             @groupChildrenChange="groupDataChange(item,$event)"/>
            <base-form-view v-else :out-data="item" @onValueChange="onFormValueChange(item,$event)"/>
        </div>
    </div>

</template>

<script>

    import BaseFormView from "../../formView";
    import FormGroupView from "../../formGroupView";
    import ConditionSelectView from "../../conditionSelectView";

    export default {
        name: 'conditionDynamicView',
        components: {FormGroupView, BaseFormView,ConditionSelectView},
        data() {
            return {
                condition: {},// 条件
                children: [],// 子view
                value: null,
                conditionView:'',
                groupView:''
            }
        },
        props: {
            conditionData: {
                type: Object,
                default() {
                    return {};
                }
            },
        },
        watch: {
            conditionData: {
                handler(newValue) {
                    // 条件数据发生变化
                    console.log('检测到条件view数据变化-newConditionData=', newValue);
                    this.analysisData(newValue);
                },
                immediate: true,
                deep: true
            },
        },
        computed: {},
        mounted() {
            this.conditionView = resolve => {
                require([`../../conditionSelectView`], resolve)
            }
            this.groupView = resolve => {
                require([`../../formGroupView`], resolve)
            }
        },
        methods: {
            analysisData(newData) {
                // let parse = JSON.parse(JSON.stringify(newData));

                let {label, value, required, opinions, items, children} = newData;
                console.log('label=', label);
                this.items = items;
                this.value = value;
                this.children = children[0];
                this.condition = {
                    label, value, required, opinions,
                    type: 'select'
                }

                if (value && this.$verify.isEmptyArray(this.children)) {
                    this.onConditionSelect(value);
                }

                console.log('this.conditionData=', this.conditionData, '-label=', this.conditionData.label)
            },
            groupDataChange(groupData, children) {
                console.log('groupDataChange-groupData', groupData, '-children=', children)
                this.$set(groupData, 'children', children);
                this.notifyDataChange();
            },
            onFormValueChange(item, newValue) {
                console.log('onFormValueChange-item', item, '-newValue=', newValue)
                this.$set(item, 'value', newValue);
                this.notifyDataChange();
            },
            onConditionSelect(conditionValue) {
                // conditionValue为当前条件选择的值
                this.value = conditionValue;
                this.children = this.items.filter(v => {
                    return v.conditionValue == conditionValue;
                });
                this.notifyDataChange();
            },
            onConditionChange(item, newItem) {
                console.log('item=', item)
                console.log('newItem=', newItem)

                // 将newItem中改变的值赋值给item
                for (const newItemKey in newItem) {
                    if (newItem.hasOwnProperty(newItemKey)) {
                        let value = newItem[newItemKey];
                        if (item.hasOwnProperty(newItemKey) && item[newItemKey] === value) {
                            continue;
                        }
                        this.$set(item, newItemKey, value);
                    }
                }
                this.notifyDataChange();
            },
            notifyDataChange() {
                // 组装数据
                console.log('condition-notifyDataChange-this.conditionData=', this.conditionData)
                console.log('condition-notifyDataChange-this.children=', this.children)
                let copy = JSON.parse(JSON.stringify(this.conditionData));
                copy.value = this.value;
                copy.children = [this.children];
                this.$emit('onConditionDataChange', copy);
            },
            getItemWidth(formItem) {
                let style = 'margin-top-8';
                switch (formItem.type) {
                    case 'conditionView':
                    case 'group':
                        style += ' match-parent-w';
                        break
                    default:
                        style += ' width-parent-48';
                        break
                }
                return style;
            }
        }

    }
</script>

<style scoped lang="scss">
</style>
