var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-col baseInfoContainer" },
    [
      _c("span", { staticClass: "text-14-black33-b" }, [_vm._v("基础信息")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-array-h" },
        _vm._l(_vm.baseInfo, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "width-parent-48 margin-top-8" },
            [
              _c("base-form-view", {
                attrs: { "out-data": item },
                on: {
                  onValueChange: function ($event) {
                    _vm.onFormValueChange(item, $event)
                  },
                },
              }),
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm._t("dynamicForm"),
      _vm._v(" "),
      _c("span", { staticClass: "text-14-black33-b margin-top-16" }, [
        _vm._v("领证信息"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-array-h match-parent-w" },
        _vm._l(_vm.getCertificateInfo, function (item, index) {
          return _c(
            "div",
            { key: index, class: _vm.getItemWidth(item) },
            [
              item.type === "conditionView"
                ? _c(
                    "div",
                    [
                      _c("condition-form-view", {
                        staticClass: "match-parent-w",
                        attrs: { "condition-data": item },
                        on: {
                          onConditionDataChange: function ($event) {
                            _vm.onConditionChange(item, $event)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _c("base-form-view", {
                    attrs: { "out-data": item },
                    on: {
                      onValueChange: function ($event) {
                        _vm.onFormValueChange(item, $event)
                      },
                    },
                  }),
            ],
            1
          )
        }),
        0
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }