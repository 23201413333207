var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "cover flex-column flex-center" }, [
        _c("div", { staticClass: "LoadingArea flex-column flex-center" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "font-size14 color-white marginTop8 loadingText" },
            [_vm._v(_vm._s(_vm.message))]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loadIcon flex-row-center" }, [
      _c("div", { staticClass: "loadIconCenter" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }