// import config from '../config/config.json';
import Vue from 'vue'
// import api from './api.js';
import LoadingView from '../components/loading'
import ToastView from '../components/toast'

let VueInstance;
const tool = {};

tool.initContext = {
    bindCurVue(vue) {
        VueInstance = vue;
        this.initWidget();
    },
    unbindCurVue() {
        VueInstance = null;
    },
    initWidget() {
        // 扩展 vue 插件
        const ToastCon = Vue.extend(ToastView)
        const ins = new ToastCon()

        const Loading = Vue.extend(LoadingView)
        const load = new Loading()

        // 挂载 dom
        ins.$mount(document.createElement('div'))
        load.$mount(document.createElement('div'))
        // 添加到 body 后面

        document.body.appendChild(ins.$el)
        document.body.appendChild(load.$el)

        ins.visible = false;
        load.visible = false;
        // 给 vue 原型添加 toast 方法
        Vue.prototype.$toast = (msg, success = true, duration = 2000, isHen = false) => {
            ins.message = msg
            ins.isSuccess = success
            ins.visible = true
            ins.isHen = isHen

            setTimeout(() => {
                ins.visible = false
            }, duration)
        }

        Vue.prototype.$showLoading = (msg) => {
            load.message = msg
            load.visible = true
        }
        Vue.prototype.$hideLoading = () => {
            load.visible = false
        }
    },
}

/**
 *
 * 项目中需要使用到的一般不复杂全局变量-过于复杂的变量使用sdCard存储
 * isDebug:是否是调试模式-将会影响到log打印等
 * notaryId: string 用户信息-归属于哪一个公证处,
 * userMes: {} 用户信息,
 * userOcrData: {} 用户身份证信息,
 * detailData: {} 详情页面数据,
 * eventId: number 事项id,
 * wxPreData:微信小程序预加载的数据
 * idStamp:身份印记 0次办-1主办
 * nextAction:下一步应该跳转哪个页面
 * eventCurPerson:当前事项正在办理人的map数据
 */
tool.commonData = {
    isDebug: true,
    // isDebug: false,
    phoneMes: {},
    userOcrData: {},
    detailData: {},
    businessId: -1,
    idStamp: 1,
    userLoginData: {},
    extraMaterialMap: {},
}

tool.ui = {
    errorToast(e, defaultHint = "提示:", isHen) {
        tool.log.d('错误弹窗-error=', e)
        let errorMes = defaultHint + '[';
        if (e.msg) {
            errorMes += e.msg;
        }
        if (e.message) {
            errorMes += e.message;
        }
        if (e.errMsg) {
            errorMes += e.errMsg;
        }
        errorMes += "]";
        this.hideLoading();
        this.toast(errorMes, false, 2500, isHen);
    },
    // ui相关的工具
    toast(str, isSuccess = false, duration = null, isHen) {
        if (!duration) {
            duration = isSuccess ? 1500 : 2000;
        }
        // VueInstance.$toast(str, isSuccess, duration, isHen);
        // console.log(str);
    },
    loading(str) {
        VueInstance.$showLoading(str);
    },
    hideLoading() {
        VueInstance.$hideLoading();
    }
}

tool.log = {
    d(mes, ...params) {
        if (tool.commonData.isDebug) {
            console.log(mes, ...params);
        }
    }
}

tool.fun = {
    forbidTimeMap: {},// 放双击需要记录相关时间
    /**
     * @param btnFlag 按钮标记-可以用方法名来代表
     * @param forbidInterval
     * @returns {Promise<unknown>}
     */
    forbidDoubleClick(btnFlag, forbidInterval = 400) {
        // 获取当前按钮-上一次点击时间
        let lastTime = this.forbidTimeMap[btnFlag], curTime = Date.now();
        if (!lastTime) {
            // 如果找不到这个时间-说明是第一次点击-记录当前时间
            this.forbidTimeMap = {};// 清空时间缓存-移除了上一次其他按钮的记录
            this.forbidTimeMap[btnFlag] = curTime;
            lastTime = curTime;
        }
        // 点击了按钮
        return new Promise((resolve, reject) => {
            let timeInterval = curTime - lastTime;
            if (timeInterval > forbidInterval || timeInterval === 0) {
                // 第一次点击或已经超过2秒-允许其点击-并记录该时间
                this.forbidTimeMap[btnFlag] = curTime;
                resolve()
            } else {
                // 在时间内-拒绝其点击-并且不更新上次点击时间
                reject(Error("请勿频繁点击！"));
            }
        });
    }
}

tool.util = {
    /**
     * 解析后端返回的正则表达式String为真正可以使用的正则表达式
     * @param originReg
     * @returns {RegExp}
     */
    getRealReg(originReg) {
        let realRs = originReg.substring(1, originReg.length - 1);// 去掉头尾
        return RegExp(realRs);
    },
}

export default tool;
