var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "cover flex-col-center" }, [
        _c(
          "div",
          {
            staticClass:
              "back-color-white flex-row-center border-box word-break",
            class: _vm.toastArea,
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "font-size14 color-black33 margin-left-4" },
              [_vm._v(_vm._s(_vm.message))]
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-row-center" }, [
      _c("i", { staticClass: "el-icon-warning icon-24 color-orangeFF" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }