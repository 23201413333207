import { render, staticRenderFns } from "./baseInfo.vue?vue&type=template&id=3a063efa&scoped=true&"
import script from "./baseInfo.vue?vue&type=script&lang=js&"
export * from "./baseInfo.vue?vue&type=script&lang=js&"
import style0 from "./baseInfo.vue?vue&type=style&index=0&id=3a063efa&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a063efa",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Project\\新动态表单\\hj-dynamic-form-plugin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3a063efa')) {
      api.createRecord('3a063efa', component.options)
    } else {
      api.reload('3a063efa', component.options)
    }
    module.hot.accept("./baseInfo.vue?vue&type=template&id=3a063efa&scoped=true&", function () {
      api.rerender('3a063efa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/baseInfo/src/baseInfo.vue"
export default component.exports