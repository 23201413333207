<template>
    <div v-if="visible" class="cover flex-column flex-center">
        <div class="LoadingArea flex-column flex-center">
            <div class="loadIcon flex-row-center">
                <div class="loadIconCenter"/>
            </div>
            <span class="font-size14 color-white marginTop8 loadingText">{{message}}</span>
        </div>
    </div>
</template>

<script>

    export default {
        name: "toastView",
        data() {
            return {
                status: 'ok',
                message: "这是一个toast默认弹窗!",
                visible: false,
            }
        },
        computed: {},
        methods: {}
    }
</script>

<style scoped>
    .LoadingArea {
        padding: 2vh 3vh;
        box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.1);
        border-radius: 2vh;
        background-color: rgba(0, 0, 0, 0.9);
    }

    .loadingText {
        max-width: 16vh;
    }

    .loadIcon {
        width: 7vh;
        height: 7vh;
        border-radius: 50%;
        background: conic-gradient(white, 80%, black);
        animation: turn 1s linear infinite;
    }

    .loadIconCenter {
        width: 6.7vh;
        height: 6.7vh;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 1);
    }

    /*
      turn : 定义的动画名称
      1s : 动画时间
      linear : 动画以何种运行轨迹完成一个周期
      infinite :规定动画应该无限次播放
     */
    @keyframes turn {
        0% {
            -webkit-transform: rotate(0deg);
        }
        25% {
            -webkit-transform: rotate(90deg);
        }
        50% {
            -webkit-transform: rotate(180deg);
        }
        75% {
            -webkit-transform: rotate(270deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
        }
    }
</style>
