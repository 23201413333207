<!--详情页面基础信息-->
<template>
    <div class="flex-col">
        <div v-for="(group,count) in dynamicData" :key="count">
            <form-group-view v-if="group.type === 'group'" :group-data="group"
                             @groupChildrenChange="groupDataChange(group,$event)"/>
        </div>
    </div>
</template>

<script>

    import FormGroupView from "../../formGroupView";

    export default {
        name: 'dynamicView',
        components: {
            FormGroupView
        },
        data() {
            return {
                // formData: [],
                key: '',
                index: 0,
            };
        },
        mounted() {
            console.log('this.dynamicData=', this.dynamicData)
        },
        props: {
            disable: {
                type: Boolean,
                default() {
                    return false;
                }
            },
            dynamicData: {
                type: Array,
                default() {
                    return [];
                }
            },
            businessId: {},
        },
        computed: {},
        watch: {},
        methods: {
            reload() {
                this.$forceUpdate()
            },
            getSaveData() {
                return this.dynamicData;
            },
            verifyDynamicData() {
                console.log('dynamicData=', this.dynamicData);
                if (this.$verify.isEmptyArray(this.dynamicData)) return true;
                for (const group of this.dynamicData) {
                    if (!this.verifyData(group)) {
                        return false;
                    }
                }
                console.log('动态表单数据校验通过！');
                return true;
            },
            toast(msg, flag = false) {
                let param = {event: 'toast', data: {msg, flag}};
                this.$root.$emit('jqBridge', param);
            },
            verifyData(data, fatherName = '') {
                let {label, value, children, required, regx, type} = data;
                let hintName = this.$verify.isEmpty(fatherName) ? `[${label}]` : `${fatherName}-[${label}]`;
                if (type === 'hidden') return true;
                console.log(hintName, '-value=', value)
                // 先检查自身-暂时不为group的都需要检查自身
                if (type !== 'group') {
                    if (required && this.$verify.isEmpty(value)) {
                        this.toast(`${hintName}数据为空！`);
                        return false;
                    }
                    if (!this.$verify.isEmpty(value) && !this.$verify.isEmpty(regx)) {
                        let exp = this.$tool.util.getRealReg(regx);
                        if (!(exp.test(value))) {
                            this.toast(`${hintName}的格式不正确！`);
                            return false;
                        }
                    }
                }

                // group等需要再去检查子数据
                if (type === 'group' || type === 'condition-picker' || type === 'condition-select') {
                    if (required && this.$verify.isEmptyArray(children)) {
                        this.toast(`${hintName}数据不完整！`);
                        return false;
                    }
                    // 存在children
                    for (const child of children) {
                        if (!this.$verify.isEmptyArray(child) && child.length > 0) {
                            for (const childPro of child) {
                                if (!this.verifyData(childPro, hintName)) {
                                    return false;
                                }
                            }
                        } else {
                            return this.verifyData(child, hintName);
                        }
                    }
                }

                return true;
            },
            packDynamicData() {
                return this.dynamicData;
            },
            groupDataChange(groupData, children) {
                this.$set(groupData, 'children', children);
            },
        }
    };
</script>

<style>
</style>
