<template>
    <div v-if="type !== 'hidden'" class="flex-row-center-jx formBar">
        <span class="text-14-black33 formTitle">{{label}}</span>
        <div class="requireStart margin-left-4">
            <span v-if="required" class="color-redFE">*</span>
        </div>

        <el-tooltip :disabled="showHover" effect="light" :content="hintText" placement="top">
            <span v-if="type === 'label'" class="contentRight"
                  :class="disable?'color-disable':'color-black33'">{{value}}</span>

            <el-input v-if="type === 'single' || type === 'number'"
                      :type="type==='number'?'number':'text'"
                      @input="watchInput($event)"
                      class="contentRight" @change="onValueChange" v-model="value"
                      placeholder="请输入内容" @mousewheel.native.prevent clearable>
                <template v-if="needEnd" slot="append">%</template>
            </el-input>

            <el-select v-if="type === 'select'" class="contentRight" v-model="value" placeholder="请选择"
                       @change="onValueChange">
                <el-option v-for="item in opinions" :key="item.label" :label="item.label" :value="item.value"/>
            </el-select>

            <el-date-picker v-if="type === 'datetime'" v-model="value" type="date" placeholder="选择日期"
                            class="contentRight" @change="onValueChange" value-format="timestamp"/>

            <el-input-number v-if="type === 'count'" class="contentRight" v-model="value"
                             @change="onValueChange" :min="1" :label="label"></el-input-number>
        </el-tooltip>
    </div>
</template>

<script>

    export default {
        name: 'baseFormView',
        data() {
            return {
                label: '默认头部',
                value: '',
                type: 'select',
                opinions: [],
                required: false,
                disable: false,
                hintText: '',
                maxLength: null
            }
        },
        props: {
            outData: {
                type: Object,
                default() {
                    return {};
                }
            }
        },
        watch: {
            outData: {
                handler(newValue) {
                    // 外部数据发生变化
                    this.analysisData(newValue);
                },
                immediate: true
            },
        },
        components: {},
        computed: {
            showHover() {
                return this.$verify.isEmpty(this.hintText);
            },
            needEnd() {
                return false;
                // return this.type == 'number';
            }
        },
        mounted() {
        },
        methods: {
            analysisData(newData) {
                let {label, value, required, type, opinions, hintText, regx,maxLength} = newData;
                this.label = label;
                this.value = value;
                this.type = type;
                this.regx = regx;
                this.required = required;
                this.opinions = opinions;
                this.hintText = hintText;
                this.maxLength = maxLength;

                // 如果select没有value-进行默认选取操作
                if (type === 'select' && this.$verify.isEmpty(value)
                    && !this.$verify.isEmptyArray(opinions)) {
                    let {label,value} = this.opinions[0];
                    console.log(label,'-进行初始值设置-value=',value);
                    this.value = value;
                    this.onValueChange(value);
                }
            },
            onValueChange(inputValue) {
                this.$emit('onValueChange', inputValue);
            },
            watchInput(data) {
                if (!this.$verify.isEmpty(this.maxLength)) {
                    if (data.length > this.maxLength) {
                        this.value = data.slice(0, this.maxLength);
                        console.log('修剪后的数据', data.slice(0, this.maxLength));
                    }
                }
            },
        }

    }
</script>

<style lang="scss">
    @import "src/css/form-style";

    .formBar {
        min-height: 2.4vw;
        height: 100%;
        font-size: 14px;
        justify-content: end;

        .contentRight {
            margin-left: $px8;
            min-width: 70%;
            max-width: 70%;
            font-size: $px14;
        }
    }

    .color-disable {
        color: #c0c4cc;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }
</style>
