var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-col" },
    [
      _c("div", { staticClass: "text-14-black33-b margin-top-16" }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
      _vm._v(" "),
      _vm._l(_vm.children, function (child, count) {
        return _c(
          "div",
          {
            key: count,
            staticClass: "flex-col margin-top-16",
            class: _vm.groupBoxStyle,
          },
          [
            _vm.showGroupHandlerBar
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flex-row-center-jx flex-between padding-h-16 height-32",
                    class: _vm.repeatable ? "border-bottom-1" : "border-eb-1",
                  },
                  [
                    _c("div", { staticClass: "barTitle flex-row-center-jx" }, [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.repeatable,
                              expression: "repeatable",
                            },
                          ],
                          staticClass: "text-14-black33",
                        },
                        [_vm._v(_vm._s(_vm.label) + _vm._s(count + 1))]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.hintLabel
                      ? _c("span", { staticClass: "text-14-gray99" }, [
                          _vm._v(_vm._s(_vm.hintLabel)),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.ocrConfigs && _vm.ocrConfigs.length > 0
                      ? _c(
                          "div",
                          { staticClass: "scanArea flex-row-center-jx" },
                          _vm._l(_vm.ocrConfigs, function (config, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass:
                                  "flex-row-center-jx btn-hover margin-left-4",
                                on: {
                                  click: function ($event) {
                                    _vm.doOcr(count, config)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-camera color-orangeFF",
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-14-orangeFF margin-left-4",
                                  },
                                  [_vm._v(_vm._s(config.label))]
                                ),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "flex-row flex-between" }, [
              _c(
                "div",
                { staticClass: "flex-array-h margin-bottom-16 match-parent-w" },
                _vm._l(child, function (pro, index) {
                  return _c(
                    "div",
                    { key: index, class: _vm.getItemWidth(pro) },
                    [
                      pro.type === "condition-picker" ||
                      pro.type === "condition-select"
                        ? _c(_vm.conditionView, {
                            tag: "div",
                            attrs: { "condition-data": pro },
                            on: {
                              onConditionDataChange: function ($event) {
                                _vm.onConditionChange(pro, $event)
                              },
                            },
                          })
                        : pro.type === "group"
                        ? _c(_vm.groupSecond, {
                            tag: "div",
                            attrs: { "group-data": pro },
                            on: {
                              groupChildrenChange: function ($event) {
                                _vm.groupDataChange(pro, $event)
                              },
                            },
                          })
                        : _c("base-form-view", {
                            attrs: { "out-data": pro },
                            on: {
                              onValueChange: function ($event) {
                                _vm.onFormValueChange(pro, $event)
                              },
                            },
                          }),
                    ],
                    1
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm.couldEdit
                ? _c(
                    "div",
                    {
                      staticClass: "deleteBtn flex-col-center btn-hover",
                      on: {
                        click: function ($event) {
                          _vm.deleteCur(child, count)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "text-14-gray66" }, [
                        _vm._v(_vm._s(count === 0 ? "重置" : "删除")),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]),
          ]
        )
      }),
      _vm._v(" "),
      _vm.couldEdit
        ? _c(
            "div",
            {
              staticClass: "addNewBox height-40 flex-row-center btn-hover",
              on: { click: _vm.addNewOne },
            },
            [
              _c("i", { staticClass: "el-icon-plus color-orangeFF" }),
              _vm._v(" "),
              _c("span", { staticClass: "text-14-orangeFF margin-left-4" }, [
                _vm._v("新增" + _vm._s(_vm.label)),
              ]),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }