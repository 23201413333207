var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-col" },
    _vm._l(_vm.dynamicData, function (group, count) {
      return _c(
        "div",
        { key: count },
        [
          group.type === "group"
            ? _c("form-group-view", {
                attrs: { "group-data": group },
                on: {
                  groupChildrenChange: function ($event) {
                    _vm.groupDataChange(group, $event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }