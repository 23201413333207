var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-array-h" },
    [
      _c("base-form-view", {
        staticClass: "width-parent-48 conditionBox",
        attrs: { "out-data": _vm.condition },
        on: { onValueChange: _vm.onConditionSelect },
      }),
      _vm._v(" "),
      _vm._l(_vm.children, function (item, index) {
        return _c(
          "div",
          { key: index, class: _vm.getItemWidth(item) },
          [
            item.type === "conditionView"
              ? _c("condition-form-view", {
                  staticClass: "match-parent-w",
                  attrs: { "condition-data": item },
                  on: {
                    onConditionDataChange: function ($event) {
                      _vm.onConditionChange(item, $event)
                    },
                  },
                })
              : item.type === "group"
              ? _c("form-group-view", {
                  attrs: { "group-data": item },
                  on: {
                    groupChildrenChange: function ($event) {
                      _vm.groupDataChange(item, $event)
                    },
                  },
                })
              : _c("base-form-view", {
                  attrs: { "out-data": item },
                  on: {
                    onValueChange: function ($event) {
                      _vm.onFormValueChange(item, $event)
                    },
                  },
                }),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }