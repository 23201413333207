/**
 * 通用常量存放处/全局缓存
 */
const cacheArea = {
    // 如事项id等
    curBusinessId: null,
    haveBindPhone: true,
    accessToken: null,// 网络框架需要使用到的token
    pageTransData: {},// 页面传输数据
};

/**
* 与jq约定的事件名字
*/
const events ={
    EVENT_DO_OCR:'doOcrNow',
}

const common = {
    PLATFORM_WX: 'platform_of_wx_min_pro',// 微信小程序
    // 相关key
    KEY_ACTION_JUMP: 'actionJump'
};

export default Object.assign({},common,events);
