<template>
    <div v-if="visible" class="cover flex-col-center">
        <div :class="toastArea" class="back-color-white flex-row-center border-box word-break">
            <div class="flex-row-center">
                <i class="el-icon-warning icon-24 color-orangeFF" />
            </div>
            <span class="font-size14 color-black33 margin-left-4">{{message}}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "toastView",
        data() {
            return {
                isSuccess: true,
                message: "这是一个toast默认弹窗!",
                visible: false,
                isHen: false,// 是否横行显示Toast
            }
        },
        computed: {
            toastIcon() {
                return this.isSuccess ? this.successIcon : this.failIcon;
            },
            toastArea() {
                return this.isHen ? 'ToastAreaHen' : 'ToastArea';
            }
        },
        methods: {
        }
    }
</script>

<style scoped>
    .ToastArea, .ToastAreaHen {
        padding: 2vw 3vw;
        box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.1);
        border-radius: 1vh;
        max-width: 66vw;
    }

    .ToastAreaHen {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }


</style>
