<template>
    <div class="flex-col">
        <div class="text-14-black33-b margin-top-16">{{label}}</div>
        <div v-for="(child,count) in children" :key="count"
             :class="groupBoxStyle" class="flex-col margin-top-16">
            <!--操作bar-->
            <div v-if="showGroupHandlerBar" class="flex-row-center-jx flex-between padding-h-16 height-32"
                 :class="repeatable?'border-bottom-1':'border-eb-1'">
                <div class="barTitle flex-row-center-jx">
                    <span v-show="repeatable" class="text-14-black33">{{label}}{{count+1}}</span>
                </div>
                <span v-if="hintLabel" class="text-14-gray99">{{hintLabel}}</span>
                <div v-if="ocrConfigs && ocrConfigs.length>0" class="scanArea flex-row-center-jx">
                    <div v-for="(config,index) in ocrConfigs" :key="index" @click="doOcr(count,config)"
                         class="flex-row-center-jx btn-hover margin-left-4">
                        <i class="el-icon-camera color-orangeFF"/>
                        <span class="text-14-orangeFF margin-left-4">{{config.label}}</span>
                    </div>
                    <!--                    <div class="flex-row-center-jx btn-hover" @click="doOcr(count)">-->
                    <!--                        <i class="el-icon-camera color-orangeFF"/>-->
                    <!--                        <span class="text-14-orangeFF margin-left-4">上传并扫描</span>-->
                    <!--                    </div>-->
                </div>
            </div>
            <!--child内容-->
            <div class="flex-row flex-between">
                <div class="flex-array-h margin-bottom-16 match-parent-w">
                    <div v-for="(pro,index) in child" :key="index" :class="getItemWidth(pro)">
                        <div :is="conditionView" :condition-data="pro"
                             v-if="pro.type === 'condition-picker' || pro.type === 'condition-select'"
                             @onConditionDataChange="onConditionChange(pro,$event)"/>
                        <div :is="groupSecond" v-else-if="pro.type === 'group'" :group-data="pro"
                             @groupChildrenChange="groupDataChange(pro,$event)"/>
                        <base-form-view v-else :out-data="pro" @onValueChange="onFormValueChange(pro,$event)"/>
                    </div>
                </div>
                <div v-if="couldEdit" class="deleteBtn flex-col-center btn-hover" @click="deleteCur(child,count)">
                    <span class="text-14-gray66">{{count===0?'重置':'删除'}}</span>
                </div>
            </div>
        </div>
        <div v-if="couldEdit" class="addNewBox height-40 flex-row-center btn-hover" @click="addNewOne">
            <i class="el-icon-plus color-orangeFF"/>
            <span class="text-14-orangeFF margin-left-4">新增{{label}}</span>
        </div>
        <!--ocr文件选择-->
<!--        <el-dialog title="选择你要扫描的文件" :visible.sync="dialogVisible" width="40%">-->
<!--            <el-radio-group v-model="curOcrIndex">-->
<!--                <el-radio v-for="(ocr,index) in ocrConfigs" :key="index" :label="index" border>{{ocr.label}}</el-radio>-->
<!--            </el-radio-group>-->
<!--            <div slot="footer" class="dialog-footer">-->
<!--                <el-button @click="dialogVisible = false">取 消</el-button>-->
<!--                <el-button type="primary" @click="chooseOcr">确 定</el-button>-->
<!--            </div>-->
<!--        </el-dialog>-->
    </div>

</template>

<script>

    import FormGroupSecondView from "../../formGroupSecond";
    import ConditionSelectView from "../../conditionSelectView";
    import BaseFormView from "../../formView";

    export default {
        name: 'formGroupView',
        components: {BaseFormView, FormGroupSecondView, ConditionSelectView},
        data() {
            return {
                label: '',
                value: null,
                items: null,
                ocrConfigs: null,
                type: 'group',
                repeatable: false,
                required: false,
                key: null,
                children: null,

                dialogVisible: false,
                curOcrIndex: -1,
                conditionView: '',
                groupSecond: ''
            }
        },
        props: {
            groupData: {
                type: Object,
                default() {
                    return null;
                },
            },
            disable: {
                type: Boolean,
                default() {
                    return false;
                }
            },
        },
        watch: {
            groupData: {
                handler(newValue) {
                    // 外部数据发生变化
                    console.log('groupData-newValue=', newValue);
                    this.analysisData(newValue);
                },
                immediate: true
            },
        },
        computed: {
            groupBoxStyle() {
                let style = '';
                if (this.repeatable) {
                    style += ' border-eb-1';
                }
                return style;
            },
            showGroupHandlerBar() {
                return this.repeatable || !this.$verify.isEmptyArray(this.ocrConfigs);
            },
            couldEdit() {
                return this.repeatable && !this.disable;
            },
            hintLabel() {
                if (!this.$verify.isEmptyArray(this.ocrConfigs)) {
                    let ocrLabel = '';
                    for (let i = 0; i < this.ocrConfigs.length; i++) {
                        if (i > 0) {
                            ocrLabel += '/'
                        }
                        ocrLabel += this.ocrConfigs[i].label;
                        if (i === this.ocrConfigs.length - 1) {
                            ocrLabel += '等';
                        }
                    }
                    return `提示：请上传${ocrLabel}，识别并自动填充身份信息。`
                }
                return '';
            },
        },
        mounted() {
            this.conditionView = resolve => {
                require([`../../conditionSelectView`], resolve)
            }
            this.groupSecond = resolve => {
                require([`../../formGroupSecond`], resolve)
            }
        },
        methods: {
            analysisData(newData) {
                let {label, key, value, items, ocrConfigs, type, repeatable, required, children} = newData;
                this.label = label;
                this.value = value;
                this.items = items;
                this.ocrConfigs = ocrConfigs;
                // this.ocrConfigs = [
                //     {
                //         condition: "personal",
                //         label: "受托人身份证",
                //         ocr: "IDCardFontOCR",
                //     },
                //     {
                //         condition: "enterprise",
                //         label: "营业执照",
                //         ocr: "BizLicenseOCR",
                //     },
                // ]
                this.type = type;
                this.repeatable = repeatable;
                this.required = required;
                this.key = key;
                this.children = children;
            },
            onFormValueChange(item, newValue) {
                this.$set(item, 'value', newValue);
                this.$emit('groupChildrenChange', this.children);
            },
            groupDataChange(groupData, children) {
                this.$set(groupData, 'children', children);
                this.$emit('groupChildrenChange', this.children);
            },
            onConditionChange(item, newItem) {
                console.log('group-onConditionChange-item', item, '-newItem', newItem)
                // 将newItem中改变的值赋值给item
                for (const newItemKey in newItem) {
                    if (newItem.hasOwnProperty(newItemKey)) {
                        let value = newItem[newItemKey];
                        if (item.hasOwnProperty(newItemKey) && item[newItemKey] === value) {
                            continue;
                        }
                        this.$set(item, newItemKey, value);
                    }
                }
                this.$emit('groupChildrenChange', this.children);
            },
            async addNewOne() {
                try {
                    await this.$tool.fun.forbidDoubleClick('addNewOne');
                    let items = this.items;
                    let newOne = JSON.parse(JSON.stringify(items));
                    this.children.push(newOne);
                    this.$emit('groupChildrenChange', this.children);
                } catch (e) {
                    this.$tool.ui.errorToast(e);
                }
            },
            async deleteCur(children, position) {
                try {
                    await this.$tool.fun.forbidDoubleClick('deleteCur');
                    if (position === 0) {
                        // 清除当前数据
                        console.log('this.children=', this.children)
                        let child0 = this.children[0];
                        let copy = JSON.parse(JSON.stringify(child0));
                        for (const childPro of copy) {
                            console.log('childPro=', childPro)
                            childPro.value = undefined;
                            childPro.children = [];
                        }
                        this.$set(this.children, 0, copy);
                    } else {
                        this.children.splice(position, 1);
                    }
                    this.$emit('groupChildrenChange', this.children);
                } catch (e) {
                    this.$tool.ui.errorToast(e);
                }
            },
            doOcr(index,ocrInfo) {
                // 参数index和key都是后端需要的参数
                let ocrParam = {
                    index,ocrInfo,
                    key: this.key
                }
                console.log(`---------------[this.ocrParam]--------------`,ocrParam);
                this.$root.$emit('jqBridge', {event: 'doOcrNow', data: ocrParam});
                // if (this.$verify.isEmptyArray(this.ocrConfigs)) return;
                // if (this.ocrConfigs.length > 1) {
                //     // 多于一个ocr选项时，进行dialog选取
                //     this.dialogVisible = true;
                // } else {
                //     this.ocrParam.ocrInfo = this.ocrConfigs[0];
                //     //只有单个ocr选项时
                //     this.$root.$emit('jqBridge', {event: 'doOcrNow', data: this.ocrParam});
                // }
            },
            // chooseOcr() {
            //     this.dialogVisible = false;
            //     if (this.curOcrIndex === -1) return;
            //     if (!this.$verify.isEmptyArray(this.ocrConfigs) && this.ocrConfigs.length >= this.curOcrIndex) {
            //         this.ocrParam.ocrInfo = this.ocrConfigs[this.curOcrIndex];
            //     }
            //     this.$root.$emit('jqBridge', {event: 'doOcrNow', data: this.ocrParam});
            // },
            getItemWidth(formItem) {
                let style = '';
                switch (formItem.type) {
                    case 'condition-picker':
                    case 'condition-select':
                    case 'group':
                        style += ' match-parent-w';
                        break
                    case 'hidden':
                        style += ' clearStyle';
                        break
                    default:
                        style += ' margin-top-8 width-parent-48';
                        break
                }
                return style;
            },
        }

    }
</script>

<style scoped lang="scss">
    @import "src/css/form-style";

    .border-bottom-1 {
        border-bottom: 1px solid #EBEBEB;
    }

    .barTitle {
        min-width: 70px;
        height: 100%;
    }

    .ocrHint {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 40%;

        &:hover {
            text-overflow: inherit;
            overflow-x: scroll;
            white-space: nowrap;
        }
    }
</style>
