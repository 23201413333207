var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-col padding-20 dynamicArea" },
    [
      _c(
        "base-info-view",
        { ref: "baseInfo", attrs: { "pre-data": _vm.baseInfoPreData } },
        [
          _c("dynamic-view", {
            ref: "dynamicData",
            attrs: { slot: "dynamicForm", "dynamic-data": _vm.formData },
            slot: "dynamicForm",
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }