var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex-row match-parent-w" }, [
    _c("span", { staticClass: "text-14-black33 subFormTitle margin-top-16" }, [
      _vm._v(_vm._s(_vm.label)),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "flex-col margin-left-16 match-parent-w" }, [
      _c(
        "div",
        { staticClass: "flex-col" },
        _vm._l(_vm.children, function (child, count) {
          return _c(
            "div",
            {
              key: count,
              staticClass: "flex-row flex-between border-eb-1 margin-top-16",
            },
            [
              _vm._l(child, function (pro, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass:
                      "flex-col-center-jx match-parent-w flex-between",
                    class: index > 0 ? "borderLeft" : "",
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "text-14-black33-b subGroupLabel" },
                      [
                        _vm._v(
                          _vm._s(pro.label) + "\n                        "
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.required,
                                expression: "required",
                              },
                            ],
                            staticClass: "requireStart",
                          },
                          [_vm._v("*")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "line-h margin-bottom-16" }),
                    _vm._v(" "),
                    pro.type === "single"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: pro.value,
                              expression: "pro.value",
                            },
                          ],
                          staticClass: "secondInput",
                          attrs: {
                            placeholder: "请输入",
                            type: "text",
                            clearable: "",
                          },
                          domProps: { value: pro.value },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(pro, "value", $event.target.value)
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                )
              }),
              _vm._v(" "),
              _vm.couldEdit
                ? _c(
                    "div",
                    {
                      staticClass: "deleteBtn flex-col-center btn-hover",
                      staticStyle: { margin: "0" },
                      on: {
                        click: function ($event) {
                          _vm.deleteCur(child, count)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "text-14-gray66" }, [
                        _vm._v(_vm._s(count === 0 ? "清除" : "删除")),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            2
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.couldEdit
        ? _c(
            "div",
            {
              staticClass: "addNewBox height-40 flex-row-center btn-hover",
              on: { click: _vm.addNewOne },
            },
            [
              _c("i", { staticClass: "el-icon-plus color-orangeFF" }),
              _vm._v(" "),
              _c("span", { staticClass: "text-14-orangeFF margin-left-4" }, [
                _vm._v("新增"),
              ]),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }