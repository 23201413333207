<template>
    <div class="flex-row match-parent-w">
        <span class="text-14-black33 subFormTitle margin-top-16">{{label}}</span>
        <div class="flex-col margin-left-16 match-parent-w">
            <div class="flex-col">
                <div v-for="(child,count) in children" :key="count" class="flex-row flex-between border-eb-1 margin-top-16">
                    <div v-for="(pro,index) in child" :key="index" :class="index>0?'borderLeft':''"
                         class="flex-col-center-jx match-parent-w flex-between">
                        <span class="text-14-black33-b subGroupLabel">{{pro.label}}
                            <span v-show="required" class="requireStart">*</span>
                        </span>
                        <div class="line-h margin-bottom-16"/>
                        <input v-if="pro.type === 'single'" class="secondInput"
                               v-model="pro.value" placeholder="请输入" type="text" clearable>
                        </input>
                    </div>
                    <div v-if="couldEdit" class="deleteBtn flex-col-center btn-hover" style="margin: 0" @click="deleteCur(child,count)">
                        <span class="text-14-gray66">{{count===0?'清除':'删除'}}</span>
                    </div>
                </div>
            </div>
            <div v-if="couldEdit" class="addNewBox height-40 flex-row-center btn-hover" @click="addNewOne">
                <i class="el-icon-plus color-orangeFF"/>
                <span class="text-14-orangeFF margin-left-4">新增</span>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'formGroupSecondView',
        components: {},
        data() {
            return {
                label: '',
                value: null,
                items: null,
                ocrConfigs: null,
                type: 'group',
                repeatable: false,
                required: false,
                key: null,
                children: null,
            }
        },
        props: {
            groupData: {
                type: Object,
                default() {
                    return null;
                }
            }
        },
        watch: {
            groupData: {
                handler(newValue) {
                    // 外部数据发生变化
                    this.analysisData(newValue);
                },
                immediate: true
            },
        },
        computed: {
            couldEdit() {
                return this.repeatable && !this.disable;
            },
        },
        mounted() {
        },
        methods: {
            analysisData(newData) {
                let {label, key, value, items, ocrConfigs, type, repeatable, required, children} = newData;
                this.label = label;
                this.value = value;
                this.items = items;
                this.ocrConfigs = ocrConfigs;
                this.type = type;
                this.repeatable = repeatable;
                this.required = required;
                this.key = key;
                this.children = children;
            },
            async addNewOne() {
                try {
                    await this.$tool.fun.forbidDoubleClick('addNewOne');
                    let items = this.items;
                    let newOne = JSON.parse(JSON.stringify(items));
                    this.children.push(newOne);
                    this.$emit('groupChildrenChange', this.children);
                } catch (e) {
                    this.$tool.ui.errorToast(e);
                }
            },
            async deleteCur(children, position) {
                try {
                    await this.$tool.fun.forbidDoubleClick('deleteCur');
                    if (position === 0) {
                        // 清除当前数据
                        let child0 = this.children[0];
                        let copy = JSON.parse(JSON.stringify(child0));
                        for (const key in copy) {
                            if (copy.hasOwnProperty(key)) {
                                copy[key].value = undefined;
                            }
                        }
                        this.$set(this.children, 0, copy);
                    } else {
                        this.children.splice(position, 1);
                    }
                    this.$emit('groupChildrenChange', this.children);
                } catch (e) {
                    this.$tool.ui.errorToast(e);
                }
            },
            onFormValueChange(item, newValue) {
                console.log('item=', item)
                this.$set(item, 'value', newValue);
                console.log('item=', item)
            },
        }

    }
</script>

<style scoped lang="scss">
    @import "../../../css/form-style";

    .borderLeft {
        border-left: 1px solid #EBEBEB;
    }

    .borderBottom {
        border-bottom: 1px solid #EBEBEB;
    }

    .secondInput {
        max-height: $px32;
        height: $px32;
        line-height: $px32;
        width: 90%;
        font-size: $px12;
        border: 0;
        outline: none;
    }

    .subGroupLabel {
        width: 100%;
        text-align: center;
        line-height: $px32;
        height: $px32;
        background-color: #FAFAFA;
    }

    .subFormTitle {
        max-width: 10%;
        min-width: 10%;
        text-align: right;
        margin-left: 10px;
    }
</style>
